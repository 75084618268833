.checkboxLabel {
	padding-top: 5px;
}

input.currencyEN {
	margin-left: 15px;
}

input.currencyFR {
	margin-right: 15px;
}

.footer_privacyLink {
	font-family: museo-sans, sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 19px;
	text-align: center;
	color: #3859a2;
	margin-right: 6px;
}

.footer_termsOfUse {
	font-family: museo-sans, sans-serif;
	font-size: 14px;
	font-weight: 300;
	line-height: 18px;
	text-align: center;
	color: #3859a2;
	margin-left: 6px;
}
.liveHomeChkbox {
	margin: 16px 0 0;
}
.liveHomeChkbox a {
	color: #e81111;
}

.fds-view__homepage {
	margin-top: 16px;
}

.shareHP {
	margin-top: 48px;
	margin-bottom: 24px;
}

.customize {
	color: #333 !important;
	text-decoration: underline;
}