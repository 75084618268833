.fatFooter .cmp-fatFooter {
	background-color: #e81111;
	padding: 64px 0;
}

.fatFooter .cmp-fatFooter .sr-only {
	display: none;
}

.fatFooter .cmp-fatFooter .cmp-fatFooter__socialLinks {
	width: calc(100% - 160px);
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 40px;
}

.fatFooter .cmp-fatFooter .cmp-fatFooter__return {
	width: calc(100% - 160px);
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 32px;
}

.fatFooter .cmp-fatFooter .cmp-fatFooter__return a {
	font-family: museo-sans, sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 19px;
	color: #fff;
}

.fatFooter .cmp-fatFooter .cmp-fatFooter__return a img {
	position: relative;
	top: 2px;
}

@media (max-width: 719px) {
	.fatFooter .cmp-fatFooter .cmp-fatFooter__socialLinks {
		width: calc(100% - 40px);
	}
}

.fatFooter
	.cmp-fatFooter
	.cmp-fatFooter__socialLinks
	.cmp-fatFooter__socialLink {
	text-align: center;
	width: 28px;
	height: 28px;
	border-radius: 28px;
	background-color: #660000;
	display: inline-block;
}

.fatFooter
	.cmp-fatFooter
	.cmp-fatFooter__socialLinks
	.cmp-fatFooter__socialLink
	img {
	margin-top: 7px;
	width: 15px;
	height: 15px;
}

.fatFooter
	.cmp-fatFooter
	.cmp-fatFooter__socialLinks
	.cmp-fatFooter__socialLinksInner {
	display: flex;
	font-size: 20px;
	color: #fff;
	align-items: center;
	gap: 8px;
}

.fatFooter
	.cmp-fatFooter
	.cmp-fatFooter__socialLinks
	.cmp-fatFooter__socialLinksInner
	> a:first-child {
	margin-right: 32px;
}

.fatFooter
	.cmp-fatFooter
	.cmp-fatFooter__socialLinks
	.cmp-fatFooter__socialLinks--facebook:hover {
	background-color: #4267b2;
}

.fatFooter
	.cmp-fatFooter
	.cmp-fatFooter__socialLinks
	.cmp-fatFooter__socialLinks--instagram:hover {
	background-color: #d93175;
}

.fatFooter
	.cmp-fatFooter
	.cmp-fatFooter__socialLinks
	.cmp-fatFooter__socialLinks--pinterest:hover {
	background-color: #e60023;
}

.fatFooter .cmp-fatFooter .cmp-fatFooter__links {
	width: calc(100% - 160px);
	font-size: 0;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	flex-direction: row;
}

@media (max-width: 719px) {
	.fatFooter .cmp-fatFooter .cmp-fatFooter__links,
	.fatFooter .cmp-fatFooter .cmp-fatFooter__return {
		width: calc(100% - 40px);
	}

	.fatFooter .cmp-fatFooter .cmp-fatFooter__links {
		display: block;
	}

	.fatFooter .cmp-fatFooter .cmp-fatFooter__socialLinks,
	.fatFooter .cmp-fatFooter .cmp-fatFooter__links #section1 {
		margin-bottom: 32px;
	}

	.fatFooter .cmp-fatFooter .cmp-fatFooter__links .cmp-fatFooter__column {
		width: 100% !important;
		margin-right: 0 !important;
	}

	.fatFooter
		.cmp-fatFooter
		.cmp-fatFooter__socialLinks
		.cmp-fatFooter__socialLinksInner {
		display: inline-block;
	}

	.fatFooter
		.cmp-fatFooter
		.cmp-fatFooter__socialLinks
		.cmp-fatFooter__socialLinksInner
		> a:first-child {
		display: block;
		width: 100%;
		margin: 0 0 16px;
	}
	.fatFooter
		.cmp-fatFooter
		.cmp-fatFooter__socialLinks
		.cmp-fatFooter__socialLinksInner
		> a:not(:first-child) {
		margin-right: 8px;
	}
	.fatFooter
		.cmp-fatFooter
		.cmp-fatFooter__socialLinks
		.cmp-fatFooter__socialLinksInner
		> a:first-child
		img {
		width: 280px;
	}
}

.fatFooter .cmp-fatFooter .cmp-fatFooter__links .cmp-fatFooter__column {
	display: inline-block;
	vertical-align: top;
	color: white;
	width: auto;
}

.fatFooter
	.cmp-fatFooter
	.cmp-fatFooter__links
	.cmp-fatFooter__column:first-child {
	width: 347px;
	margin-right: 35px;
}

.fatFooter .cmp-fatFooter .cmp-fatFooter__links .cmp-fatFooter__column a {
	width: fit-content;
	display: block;
}

.fatFooter
	.cmp-fatFooter
	.cmp-fatFooter__links
	.cmp-fatFooter__column
	.cmp-fatFooter__column-title
	h2 {
	text-transform: uppercase;
	margin: 0 0 16px;
	font-size: 16px;
	font-weight: 700;
	font-stretch: normal;
	font-style: normal;
	line-height: 19px;
	letter-spacing: 1.5px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}

.fatFooter
	.cmp-fatFooter
	.cmp-fatFooter__links
	.cmp-fatFooter__column
	.downArrow {
	display: none;
}

.fatFooter
	.cmp-fatFooter
	.cmp-fatFooter__links
	.cmp-fatFooter__column
	.column-link-text {
	margin: 16px 0 0;
	font-size: 18px;
	font-weight: 400;
	font-stretch: normal;
	font-style: normal;
	line-height: 21px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}

.fatFooter
	.cmp-fatFooter
	.cmp-fatFooter__links
	.cmp-fatFooter__column
	.column-link-text-hr {
	margin: 16px 0 0;
	font-size: 18px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 21px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}

.fatFooter
	.cmp-fatFooter
	.cmp-fatFooter__links
	.cmp-fatFooter__column
	.column-link-text-p {
	margin: 16px 0 0;
	font-size: 17px;
	font-weight: 300;
	font-stretch: normal;
	font-style: normal;
	line-height: 25px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}

.fatFooter
	.cmp-fatFooter
	.cmp-fatFooter__links
	.cmp-fatFooter__column
	.column-link-text-p
	a {
	display: inline-block;
}

.fatFooter
	.cmp-fatFooter
	.cmp-fatFooter__links
	.cmp-fatFooter__column
	a
	.column-link-text:hover {
	text-decoration: underline;
	text-underline-offset: 5px;
	text-decoration-thickness: 1.25px;
}

.fatFooter
	.cmp-fatFooter
	.cmp-fatFooter__links
	.cmp-fatFooter__column
	.column-link.showLess {
	display: none;
}

.fatFooter
	.cmp-fatFooter
	.cmp-fatFooter__links
	.cmp-fatFooter__column
	.column-link.showMore {
	display: flex;
}

.fatFooter
	.cmp-fatFooter
	.cmp-fatFooter__links
	.cmp-fatFooter__column
	.collapseInitial,
.fatFooter
	.cmp-fatFooter
	.cmp-fatFooter__links
	.cmp-fatFooter__column
	.expandInitial {
	display: none;
	flex-direction: row;
	gap: 6.73px;
	align-items: center;
	margin-top: 15px;
}

.fatFooter
	.cmp-fatFooter
	.cmp-fatFooter__links
	.cmp-fatFooter__column
	.expand_collapse_text {
	font-style: normal;
	font-weight: 600;
	font-size: 11px;
	line-height: 12px;
	letter-spacing: 1.5px;
	text-transform: uppercase;
	color: #fff;
	margin: 0;
}

.fatFooter
	.cmp-fatFooter
	.cmp-fatFooter__links
	.cmp-fatFooter__column
	.expand_collapse_text:hover {
	text-decoration: underline;
	text-underline-offset: 5px;
	text-decoration-thickness: 1.25px;
}

.fatFooter.five-columns.cmp-fatFooter .cmp-fatFooter__socialLinks {
	width: 1285px;
}

.fatFooter.five-columns.cmp-fatFooter .cmp-fatFooter__links {
	width: 1285px;
}

.fatFooter.five-columns.cmp-fatFooter
	.cmp-fatFooter__links
	.cmp-fatFooter__column {
	width: 225px;
}
