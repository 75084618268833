a {
	text-decoration: none;
	color: unset;
}

.footer .global-footer {
	width: calc(100% - 160px);
	background-color: #333;
	color: white;
	font-size: 13px;
	line-height: 1.23;
	padding: 40px 80px;
	min-height: 22px;
	overflow: hidden;
}

.global-footer .footer-link {
	float: left;
	margin-top: 3px;
	margin-bottom: 15px;
}

.global-footer .footer-link:hover {
	cursor: pointer;
	text-decoration: underline white 1.5px;
	text-underline-offset: 3px;
}

.global-footer .footer-link--privacy-policy {
	color: #70a8f4;
	text-transform: uppercase;
	letter-spacing: 0.5px;
	font-size: 14.5px;
	font-weight: 700;
	line-height: 14.5px;
}

.global-footer .footer-link--privacy-policy:hover {
	text-decoration: none;
}

.global-footer .footer-link--privacy-policy__underline {
	transition: all 0.1s ease-in-out;
}

.global-footer .footer-link--privacy-policy__underline:hover {
	padding-bottom: 2px;
	border-bottom: solid 1.5px white;
	color: white;
}

.global-footer .scholastic-logo {
	width: 174px;
	height: 22px;
	margin-right: 40px;
	float: left;
}

.global-footer .footer-description {
	margin-top: 5px;
	float: left;
	font-size: 11px;
}

.global-footer p {
	font-family: museo-sans, sans-serif;
	font-weight: 300;
	line-height: 1.22;
}

.global-footer .footer-links {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-bottom: 0;
	column-gap: 40px;
}

@media (min-width: 1599px) {
	.global-footer {
		display: flex;
		column-gap: 10px;
	}
}

@media (max-width: 1279px) {
	.global-footer {
		padding-left: 60px;
		width: calc(100% - 60px);
	}

	.global-footer .scholastic-logo {
		margin-right: 26px;
	}
}

@media (max-width: 959px) {
	.global-footer {
		width: 100%;
		padding-left: 0;
		text-align: center;
	}

	.global-footer .scholastic-logo {
		width: 100%;
		margin-bottom: 32px;
	}

	.global-footer .footer-links {
		width: 100%;
		margin-bottom: 24px;
		justify-content: center;
	}

	.global-footer .footer-link {
		margin-top: 0;
		float: none;
		padding-bottom: 0;
	}

	.global-footer .footer-link--privacy-policy {
		margin-bottom: 15px;
	}

	.global-footer .footer-description {
		float: none;
		margin: 0;
		width: 100%;
	}
}

@media (max-width: 719px) {
	.footer .global-footer {
		padding: 40px 20px;
		width: calc(100% - 40px);
	}

	.global-footer .scholastic-logo {
		margin-bottom: 24px;
	}

	.global-footer .footer-links {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		margin-bottom: 0;
		column-gap: 40px;
	}

	.global-footer .footer-link {
		padding-bottom: 2px;
		display: unset;
		position: relative;
		margin-bottom: 24px;
	}

	.global-footer .footer-link:last-of-type {
		width: 100%;
	}

	.global-footer .footer-link--privacy-policy {
		display: block;
		margin-bottom: 24px;
		position: relative;
	}
}

@media (max-width: 519px) {
	.global-footer .footer-link--privacy-policy {
		display: inline-block;
		width: 100%;
	}
}
