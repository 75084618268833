.react-datepicker__triangle {
	display: none;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
	background-color: white;
	border-bottom: 0;
}
.react-datepicker__header {
	text-align: center;
	background-color: #f0f0f0;
	padding: 20px 0 0 0;
	position: relative;
}
.react-datepicker__month-container {
	padding: 0px 25px 11.75px 25px;
}

.react-datepicker__day-name,
.react-datepicker__day {
	width: 28px;
}

.react-datepicker__current-month {
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 15px;
	text-align: center;
	color: #242424;
	margin-bottom: 9.25px;
}

.react-datepicker__day--outside-month.react-datepicker__day.react-datepicker__day.react-datepicker__day--highlighted,
.react-datepicker__day--outside-month.react-datepicker__day.react-datepicker__day,
.react-datepicker__day--selected.react-datepicker__day--outside-month {
	color: white;
	background-color: white;
	pointer-events: none;
}

.react-datepicker__input-container input {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 40px;
	background: #ffffff;
	box-sizing: border-box;
	border-radius: 5px;
	font-family: museo-sans, sans-serif;
	font-size: 16px;
	line-height: 19px;
	color: #333333;
	padding: 0px 0 0 45px;
	border: 1px solid #919191;
}

.react-datepicker__input-container input:focus {
	outline: 2px auto #116eee;
}

.react-datepicker__navigation {
	width: 50px;
	height: 34px;
}
.react-datepicker__navigation-icon--previous::before {
	background: url("../images/BlueArrow.svg") no-repeat;
	width: 8px;
	height: 14px;
	border: none;
	transform: none;
	position: absolute;
	top: 15px;
	left: 10px;
	color: #116eee;
}

.react-datepicker {
	background: #ffffff;
	border: 1px solid #d7d7d7;
	box-sizing: border-box;
	box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
}
.react-datepicker__navigation--next {
	right: 30px;
}
.react-datepicker__navigation-icon--next::before {
	background: url("../images/BlueArrow.svg") no-repeat;
	width: 8px;
	height: 14px;
	border: none;
	transform: rotate(180deg);
	position: absolute;
	top: 15px;
	left: 10px;
}
.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected.react-datepicker__day--selected,
.react-datepicker__day.react-datepicker__day--selected {
	border-radius: 50%;
	background: #116eee;
	color: #fff;
	font-weight: bold;
}

.react-datepicker__day--keyboard-selected {
	background: transparent;
}

.react-datepicker__day--selected:focus,
.react-datepicker__day:focus,
.react-datepicker__day--keyboard-selected:focus,
.react-datepicker__day--highlighted.react-datepicker__day--keyboard-selected:focus {
	outline: 2px solid #116eee;
	outline-offset: 2px;
	border-radius: 50%;
	background: #116eee;
	color: #fff;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day:hover,
.react-datepicker__day--keyboard-selected:hover {
	border-radius: 50%;
	background: #004eb9;
	color: #fff;
}

.react-datepicker__day--selected:active,
.react-datepicker__day:active,
.react-datepicker__day--keyboard-selected:active {
	border-radius: 50%;
	background: #002d70;
	color: #fff;
}

.react-datepicker__day-names {
	margin-bottom: -5px;
}

.react-datepicker__day.react-datepicker__day--disabled {
	color: #919191;
	font-weight: normal;
}

.react-datepicker__day {
	color: #116eee;
	font-weight: bold;
	border-radius: none;
	background-color: transparent;
}

.react-datepicker__day--outside-month.react-datepicker__day.react-datepicker__day.react-datepicker__day--highlighted,
.react-datepicker__day--outside-month.react-datepicker__day.react-datepicker__day,
.react-datepicker__day--selected.react-datepicker__day--outside-month.react-datepicker__day--selected:focus {
	outline: none;
}
