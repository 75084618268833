.ca-footer {
	position: relative;
	z-index: 100;
}

.ca-copyright {
	background-color: #660000;
	color: #fff;
	font-size: 13px;
	line-height: 15px;
	font-family: museo-sans, sans-serif;
	font-weight: 400;
	height: 64px;
	display: flex;
	justify-content: center;
	align-items: center;
}
