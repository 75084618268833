@charset "UTF-8";
@import url("https://use.typekit.net/qvo7ray.css");

html.bookfairs-modal--open {
	overflow: hidden;
}

body {
  margin: 0;
  font-family: museo-sans, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &::-webkit-scrollbar {
		width: 16px;
	}
	&::-webkit-scrollbar-thumb {
		border: 4px solid transparent;
		background-clip: padding-box;
		-webkit-border-radius: 10px;
		background-color: #666666;
	}
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

p {
	margin: 0;
}

.innerPages {
	position: relative;
}
